import {
  Alert,
  AppLayout,
  SideNavigation
} from '@cloudscape-design/components';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
// Page components
import { MainPage } from './Screens/MainPage';
import { DocumentTableView } from './Screens/DocumentTableView';
import { TopNavigationBar } from './Screens/Components/TopNavigation';
import { DocListHelpPanel } from './Screens/sidePanel/doclistHelpPanel';
import { UploadHelpPanel } from './Screens/sidePanel/uploadSidePanel';
import { DocumentContext } from './context/documentContext';
import { UploadCSV } from './Screens/Components/UploadCSV';
// import { BaseLayout } from './Screens/Components/SynopsisPage1';
// import { DetailPage1 } from './Screens/Components/TopicOverview';
// sidepanels
import { ViewerSidePanel } from './Screens/sidePanel/docviewerSidePanel';
import { useContext, useEffect, useState } from 'react';
import { ProtectedRoute } from './Screens/ProtectedRoute';
import { AdminRoute } from './Screens/AdminRoute';
import { EditDomains } from './Screens/Components/EditDomainControls';
import { ManageUsers } from './Screens/Components/ManageUsers';
import { ChangePassword } from './Screens/Components/ChangePassword';
import { Reports } from './Screens/Components/reports';
import { UserContext } from './context/authContext';
import { ErrorReports } from './Screens/Components/errorReportPage';
import { FreeformAnalysis } from './Screens/Components/freeformAnalysis';
import { ClipboardSidePanel } from './Screens/sidePanel/copyToClipboardPanel';
import './App.css';
import { KnowledgeBaseSplash } from './Screens/Components/KnowledegeBaseSplash';
import { DocumentViewerQuestionaire } from './Screens/DocumentViewerQuestionaire';
import { QuestionaireSidePanel } from './Screens/sidePanel/questionaireSideView';
import { DocumentLayoutViewer } from './Screens/DocumentLayoutViewer';
import 'pdfjs-dist/build/pdf.worker.min.mjs';
import { ReportsView } from './Screens/Components/reportsView';
import { ProjectTableView } from './Screens/projectTableView';
import { DocumentViewerContainer } from './Screens/DocumentViewerContainer';
import { ErrorBoundary } from 'react-error-boundary';
import { UploadDocument } from './Screens/Components/uploadViews/UploadDoc';
import { ProjectBreadcrumbs } from './Screens/breadCrumbs/projectBreadcrumbs';

export function App() {
  const {
    selectedItems,
    freeformSelectedItemsKB,
    freeformSelectedItemsQA,
    mainAlert,
    setMainAlert
  } = useContext(DocumentContext);
  const { user } = useContext(UserContext);
  const windowWidth = window.innerWidth;
  const [navItems, setNavItems] = useState<any[]>([
    // { type: 'link', text: 'Analyze Documents', href: 'upload' },
    // {
    //   type: 'link',
    //   text: 'Answer Questionnaires & Surveys',
    //   href: 'upload?type=questionnaire'
    // },
    // { type: 'link', text: 'List Documents', href: 'documents' },
    { type: 'link', text: 'Projects', href: 'projects' },
    { type: 'link', text: 'Search', href: 'freeform' },
    { type: 'divider' }
  ]);
  const [toolsOpen, setToolsOpen] = useState<boolean>(false);
  const [navOpen, setNavOpen] = useState<boolean>(true);
  const [mainContainerWidth, setMainContainerWith] = useState<number>(90);
  const navigate = useNavigate();
  let location = useLocation();
  useEffect(() => {
    if (user.isAdmin) {
      const temp = navItems;
      const adminSection = {
        type: 'section',
        text: 'Admin',
        items: [
          { type: 'link', text: 'Populate Database', href: 'admin/uploadcsv' },
          { type: 'link', text: 'Edit Controls / Domains', href: 'admin/edit' },
          { type: 'link', text: 'Manage Users', href: 'admin/users' },
          { type: 'link', text: 'Error Logs', href: 'admin/errorlogs' }
        ]
      };
      temp.push(adminSection);
      console.log('temp side nav', temp);
      setNavItems([...temp]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.isAdmin]);
  // useEffect(() => {
  //   if (
  //     selectedItems.length > 0 &&
  //     !location.pathname.includes('questionnaire')
  //   ) {
  //     setToolsOpen(true);
  //   }
  // }, [selectedItems]);
  useEffect(() => {
    if (
      (freeformSelectedItemsKB.length > 0 ||
        freeformSelectedItemsQA.length > 0) &&
      location.pathname === '/app/freeform'
    ) {
      setToolsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freeformSelectedItemsKB, freeformSelectedItemsQA]);
  useEffect(() => {
    if (toolsOpen) {
      setNavOpen(false);
    }
  }, [toolsOpen]);
  useEffect(() => {
    if (location.pathname.includes('questionnaire')) {
      console.log('closing tools');
      setToolsOpen(false);
    }
  }, [location.pathname, setToolsOpen]);
  useEffect(() => {
    if (location.pathname.includes('base')) {
      setNavOpen(false);
      setMainContainerWith(95);
    } else {
      setMainContainerWith(80);
    }
  }, [location.pathname]);

  return (
    <>
      <div
        style={{
          // position: 'fixed',
          top: 0,
          width: '100%',
          overflowY: 'scroll'
        }}
      >
        <TopNavigationBar />

        <AppLayout
          // minContentWidth={windowWidth * 0.65}
          disableContentPaddings
          headerSelector=".b"
          toolsWidth={400}
          toolsOpen={toolsOpen}
          onToolsChange={({ detail }) => setToolsOpen(detail.open)}
          navigationOpen={navOpen}
          onNavigationChange={() => {
            const newState = !navOpen;
            setNavOpen(newState);
          }}
          navigation={
            <SideNavigation
              header={{ href: 'landing', text: 'Services' }}
              items={navItems}
              onFollow={(event) => {
                if (!event.detail.external) {
                  event.preventDefault();
                  navigate(`${event.detail.href}`);
                }
              }}
            />
          }
          content={
            <div
              style={{
                width: `${mainContainerWidth}%`,
                // paddingLeft: '1%',
                // maxHeight: '90vh',
                // alignItems: 'center',
                // alignContent: 'center',
                // justifyContent: 'center',
                margin: '1% auto',
                overflowY: 'scroll',
                scrollbarWidth: 'none'
              }}
            >
              {mainAlert.showAlert ? (
                <Alert
                  type={mainAlert.alertType}
                  dismissible
                  onDismiss={() =>
                    setMainAlert({
                      showAlert: false,
                      message: '',
                      alertType: 'success'
                    })
                  }
                >
                  {mainAlert.message}
                </Alert>
              ) : null}
              <Routes>
                {/* <Route element={<ProtectedRoute />}> */}
                <Route path="landing" element={<MainPage />} />
                <Route path="projects" element={<DocumentViewerContainer />} />
                <Route path="upload" element={<UploadDocument />} />
                {/* <Route path="documents" element={<DocumentTableView />} /> */}
                <Route path="reports" element={<ReportsView />} />
                <Route path="changepassword" element={<ChangePassword />} />
                <Route path="freeform" element={<FreeformAnalysis />} />
                {/* <Route path="base" element={<BaseLayout />} /> */}
                <Route
                  path="questionnaire"
                  element={
                    <DocumentViewerQuestionaire
                      setShowSynopsis={function (): void {
                        throw new Error('Function not implemented.');
                      }}
                    />
                  }
                />
                <Route path="splash" element={<KnowledgeBaseSplash />} />
                <Route
                  path="base"
                  element={
                    <ErrorBoundary fallback={<p>Something up homie!</p>}>
                      <DocumentLayoutViewer />
                    </ErrorBoundary>
                  }
                />
                {/* <Route path="filtered" element={<DocumentViewerFiltered  />} /> */}
                {/* </Route> */}
                <Route path="/admin/*" element={<AdminRoute />}>
                  <Route path="uploadcsv" element={<UploadCSV />} />
                  <Route path="edit" element={<EditDomains />} />
                  <Route path="users" element={<ManageUsers />} />
                  <Route path="errorlogs" element={<ErrorReports />} />
                </Route>
              </Routes>
            </div>
          }
          tools={
            <>
              <Routes>
                <Route path="upload" element={<UploadHelpPanel />} />
                <Route path="documents" element={<DocListHelpPanel />} />
                <Route path="base" element={<ViewerSidePanel />} />
                <Route path="freeform" element={<ClipboardSidePanel />} />
                <Route path="projects" element={<DocListHelpPanel />} />å
                <Route
                  path="questionnaire"
                  element={<QuestionaireSidePanel />}
                />
              </Routes>
            </>
          }
          // breadcrumbs={
          //   <>
          //     <Routes>
          //       <Route path="projects" element={<ProjectBreadcrumbs />} />
          //     </Routes>
          //   </>
          // }
        />
      </div>
    </>
  );
}
