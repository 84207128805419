import { sentenceItem } from '../models/interfaces';

export const buildNavObject = (sentences: sentenceItem[]) => {
  const resultObject = sentences.reduce(
    (acc, curr: sentenceItem) => {
      if (!acc[curr.predicted_label_control]) {
        acc[curr.predicted_label_control] = [curr.PageNumber!];
      } else {
        acc[curr.predicted_label_control].push(curr.PageNumber!);
      }
      return acc;
    },
    {} as { [key: string]: number[] }
  );
  const controlKeys = Object.keys(resultObject);
  for (const controlKey of controlKeys) {
    const tempArray = resultObject[controlKey];
    const tempSet = new Set(tempArray);
    const tempUniqueArray = Array.from(tempSet);
    tempUniqueArray.sort((a, b) => a - b);
    resultObject[controlKey] = tempUniqueArray;
  }
  return resultObject;
};
