import React, {
  useState,
  ReactNode,
  useEffect,
  useRef,
  useCallback
} from 'react';

interface PopoverWrapperProps {
  children: ReactNode;
  popoverContent: ReactNode;
  sideToShow: 'LEFT' | 'RIGHT';
  aboveBelow: 'TOP' | 'BOTTOM';
}

const PopoverWrapper: React.FC<PopoverWrapperProps> = ({
  children,
  popoverContent,
  sideToShow,
  aboveBelow
}) => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [showPopover, setShowPopover] = useState(false);
  const [hovering, setHovering] = useState(false);
  const [contentWidth, setContentWidth] = useState<number>(0);
  const [contentHeight, setContentHeight] = useState<number>(0);
  // const [offsetWidth, setOffSetWidth] = number
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (hovering) {
      timer = setTimeout(() => setShowPopover(true), 1000);
    } else {
      setShowPopover(false);
      if (timer!) {
        clearTimeout(timer);
      }
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [hovering]);

  const decideTransform = () => {
    if (sideToShow === 'RIGHT' && aboveBelow === 'TOP') {
      return `translateX(${elementRef.current?.offsetWidth}px) translateY(-${contentHeight}px)`;
    }
    if (sideToShow === 'LEFT' && aboveBelow === 'TOP') {
      return `translateX(-${contentWidth}px) translateY(-${contentHeight}px)`;
    }
    if (sideToShow === 'RIGHT' && aboveBelow === 'BOTTOM') {
      return `translateX(${elementRef.current?.offsetWidth}px) translateY(${contentHeight}px)`;
    }
    if (sideToShow === 'LEFT' && aboveBelow === 'BOTTOM') {
      return `translateX(-${contentWidth}px) translateY(${contentHeight}px)`;
    }
  };

  const handleMouseEnter = () => {
    setHovering(true);
  };

  const handleMouseLeave = () => {
    setHovering(false);
  };
  const measureRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      console.log('width', node.getBoundingClientRect().height);
      setContentWidth(node.getBoundingClientRect().width);
      setContentHeight(node.getBoundingClientRect().height);
    }
  }, []);

  return (
    <div
      ref={elementRef}
      id="childHolder"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        display: 'inline-block',
        position: 'relative'
      }}
    >
      {children}
      {showPopover && (
        <div
          ref={measureRef}
          style={{
            position: 'absolute',
            top: '0%',
            left: '0%',

            transform: decideTransform(),
            padding: '10%',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            zIndex: '10',
            whiteSpace: 'nowrap',
            borderRadius: '5px'
          }}
        >
          {popoverContent}
        </div>
      )}
    </div>
  );
};

// const Wrapper = styled.div`
//   display: inline-block;
//   position: relative;
// `;

// const Popover = styled.div`
//   position: absolute;
//   top: 100%;
//   left: 50%;
//   transform: translateX(-50%);
//   padding: 10px;
//   background-color: #fff;
//   border: 1px solid #ccc;
//   box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
//   z-index: 10;
//   white-space: nowrap;
// `;

export default PopoverWrapper;
