import { useCollection } from '@cloudscape-design/collection-hooks';
import {
  Box,
  Container,
  Table,
  SpaceBetween,
  Button,
  Header,
  Modal,
  FormField,
  Input,
  Pagination
} from '@cloudscape-design/components';
import { FolderItem, Projects } from '../models/interfaces';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProjectContext } from '../context/projectContext';
import { DocumentContext } from '../context/documentContext';

export const FolderTableView = (props: {
  folders: FolderItem[];

  setViewFolders: (arg: boolean) => void;
}) => {
  const { folders, setViewFolders } = props;
  const {
    selectedProjects,
    setSelectedProjects,
    createNewFolder,
    setFolderName,
    folderName,
    createFolderLoading,
    updateFolder,
    newFolderModalVisible,
    setNewFolderModalVisible,
    deleteFolder,
    selectedFolder,
    setSelectedFolder
  } = useContext(ProjectContext);
  const { allDocuments, setCurrentFolder } = useContext(DocumentContext);

  const [modalType, setModalType] = useState<string>('');
  console.log('props in folder table', folders);
  const navigate = useNavigate();

  const handleReset = () => {
    setNewFolderModalVisible(false);
    setFolderName('');
  };
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps
  } = useCollection(folders, {
    filtering: {
      empty: <Box>Nothing</Box>,
      noMatch: <Box>No matches</Box>
    },
    pagination: { pageSize: 10 },
    sorting: {},
    selection: {}
  });
  const { selectedItems } = collectionProps;
  useEffect(() => {
    if (selectedItems && selectedItems.length > 0) {
      setSelectedFolder(selectedItems[0]);
      setCurrentFolder(selectedItems[0]._id);
    }
  }, [selectedItems]);

  const countDocuments = (folderId: string) => {
    const thisFolderDocuments = allDocuments.filter(
      (ele) => ele.folder === folderId
    );
    return thisFolderDocuments.length;
  };

  const handleSave = () => {
    if (modalType === 'CREATE') {
      createNewFolder();
    }
    if (modalType === 'EDIT') {
      updateFolder(selectedFolder._id);
    }
  };
  return (
    <>
      <Container
        header={
          <Header
            actions={
              <SpaceBetween direction="horizontal" size="s">
                <Button onClick={() => deleteFolder(selectedFolder._id)}>
                  Delete
                </Button>
                <Button
                  onClick={() => {
                    setNewFolderModalVisible(true);
                    setModalType('EDIT');
                    setFolderName(selectedFolder.folderName);
                  }}
                  disabled={!selectedFolder._id ? true : false}
                >
                  Edit Folder
                </Button>
                <Button
                  onClick={() => {
                    setNewFolderModalVisible(true);
                    setModalType('CREATE');
                  }}
                >
                  Create Folder
                </Button>
                <Button
                  variant="icon"
                  iconName="arrow-left"
                  onClick={() => {
                    setViewFolders(false);
                    setCurrentFolder('');
                    setSelectedProjects([]);
                    setSelectedFolder({} as FolderItem);
                    navigate('/app/projects');
                  }}
                ></Button>
              </SpaceBetween>
            }
          >
            {selectedProjects[0].projectName}
          </Header>
        }
      >
        <Table
          {...collectionProps}
          items={items}
          pagination={<Pagination {...paginationProps} />}
          columnDefinitions={[
            {
              id: 'title',
              header: 'Folder Name',
              cell: (item: FolderItem) => item.folderName,
              sortingField: 'documentFileName',
              maxWidth: '400px'
            },
            {
              id: 'id',
              header: ' Folder id',
              cell: (item: FolderItem) => item._id
            },
            {
              id: 'docCount',
              header: '# Documents',
              cell: (item: FolderItem) => countDocuments(item._id)
            }
          ]}
          selectionType="single"
          selectedItems={selectedItems}
        />
      </Container>
      <Modal
        visible={newFolderModalVisible}
        onDismiss={() => handleReset()}
        header={modalType === 'EDIT' ? 'Edit Folder Name' : 'Create New Folder'}
        footer={
          <SpaceBetween size={'s'} direction="horizontal" alignItems="end">
            <Button onClick={() => handleReset()}>Cancel</Button>
            <Button onClick={() => handleSave()} loading={createFolderLoading}>
              Save
            </Button>
          </SpaceBetween>
        }
      >
        <FormField label="Project Name">
          <Input
            value={folderName}
            onChange={(event) => setFolderName(event.detail.value)}
          />
        </FormField>
      </Modal>
    </>
  );
};
