import React, { createContext, useState, ReactNode, useEffect } from 'react';
import {
  CognitoIdentityProviderClient,
  InitiateAuthCommand,
  ListUsersCommand,
  RespondToAuthChallengeCommand
} from '@aws-sdk/client-cognito-identity-provider';
// dummy data for dev
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import { decode, JwtPayload } from 'jsonwebtoken';
import useLocalStorage from '../hooks/localStorage';
import { IUser, IUserContext } from '../models/interfaces';
import Cookies from 'js-cookie';
import { JwtToken } from 'aws-sdk/clients/appflow';
interface Props {
  children?: ReactNode;
}

export const UserContext = createContext({} as IUserContext);
const clientCognito = new CognitoIdentityProviderClient({
  region: process.env.REACT_APP_REGION
});

const getCognitoLogin = async (email: string, password: string) => {
  const clientCognito = new CognitoIdentityProviderClient({
    region: process.env.REACT_APP_REGION
  });
  const command = new InitiateAuthCommand({
    AuthFlow: 'USER_PASSWORD_AUTH', // You can also use 'USER_PASSWORD_AUTH' if you don't need admin privileges
    ClientId: process.env.REACT_APP_COGNITO_CLIENTID,
    // UserPoolId: process.env.REACT_APP_USERPOOL,
    AuthParameters: {
      USERNAME: email,
      PASSWORD: password
    }
  });
  const response = await clientCognito.send(command);

  return response;
};

const cognitoPasswordChallenge = async (
  email: string,
  password: string,
  Session: string
) => {
  const clientCognito = new CognitoIdentityProviderClient({
    region: process.env.REACT_APP_REGION
  });
  const respondToAuthChallengeCommand = new RespondToAuthChallengeCommand({
    ChallengeName: 'NEW_PASSWORD_REQUIRED',
    ClientId: process.env.REACT_APP_COGNITO_CLIENTID,
    ChallengeResponses: {
      USERNAME: email,
      NEW_PASSWORD: password
    },
    Session: Session
  });

  const challengeResponse = await clientCognito.send(
    respondToAuthChallengeCommand
  );
  return challengeResponse;
};

export const UserContextProvider: React.FC<Props> = (props) => {
  const [user, setUser] = useState<IUser>({} as IUser);
  const [token, setToken] = useState<string>('');
  const [cognitoToken, setCognitoToken] = useState<string>('');
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isDeveloper, setIsDeveloper] = useState<boolean>(false);
  const [storedToken, setStoredToken] = useLocalStorage<string>('token', '');
  const [storedAccessToken, setStoredAccessToken] = useLocalStorage<string>(
    'accessToken',
    ''
  );
  // const [storedRefreshToken, setStoredRefreshToken] = useLocalStorage<string>(
  //   'refreshToken',
  //   ''
  // );
  const [storedUser, setStoredUser] = useLocalStorage<string>('user', '');
  const [cognitoChallenge, setCognitoChallenge] = useState<boolean>(false);
  const [challengeSession, setChallengeSession] = useState<string>('');
  const [passwordUserError, setPasswordUserError] = useState<boolean>(false);
  const [expiryTime, setExpiryTime] = useState<number>(0);

  console.log('window on load', window.location);

  useEffect(() => {
    console.log('current expiry Time', expiryTime);
    const now = Math.floor(Date.now() / 1000);
    const diff =
      (expiryTime - now - Math.floor(Math.random() * (15 - 1 + 1)) + 1) * 1000;
    console.log('diff in seconds', diff);
    const timer = setTimeout(() => {
      const refreshToken = Cookies.get('refreshToken');
      if (refreshToken) {
        console.log('refreshing token');
        getNewTokenFromRefresh(refreshToken);
      } else {
        console.log('no refresh token');
      }
    }, diff);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expiryTime]);
  const getNewTokenFromRefresh = async (refreshToken: string) => {
    try {
      const command = new InitiateAuthCommand({
        AuthFlow: 'REFRESH_TOKEN_AUTH', // Specify the auth flow as REFRESH_TOKEN_AUTH
        ClientId: process.env.REACT_APP_COGNITO_CLIENTID,
        AuthParameters: {
          REFRESH_TOKEN: refreshToken // Pass the refresh token
        }
      });
      const clientCognito = new CognitoIdentityProviderClient({
        region: process.env.REACT_APP_REGION
      });
      const refreshResponse = await clientCognito.send(command);
      if (refreshResponse.AuthenticationResult?.IdToken) {
        setToken(refreshResponse.AuthenticationResult?.IdToken);
        setStoredToken(refreshResponse.AuthenticationResult?.IdToken);
        setUserFromToken(refreshResponse.AuthenticationResult?.IdToken);
        setStoredAccessToken(
          refreshResponse.AuthenticationResult?.AccessToken!
        );
        Cookies.set('token', refreshResponse.AuthenticationResult?.IdToken);
      }
    } catch (e) {
      const error = e as Error;
      console.log(error.message);
      Cookies.remove('token');
      if (error.message === 'Refresh Token has expired') {
        Cookies.remove('refreshToken');
      }
      // setStoredRefreshToken('');
      setToken('');
      setStoredAccessToken('');
    }
  };

  const isExpired = (token: JwtToken) => {
    const payload = decode(token) as JwtPayload;
    console.log('payload', payload);
    if (payload.exp && token) {
      const now = Math.floor(Date.now() / 1000);
      if (now < payload.exp) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
  useEffect(() => {
    const token = Cookies.get('token');
    const refreshToken = Cookies.get('refreshToken');
    if (token && !isExpired(token)) {
      setToken(token);
      setUserFromToken(token);
    } else {
      if (refreshToken) {
        // check ecpiretion time on token.
        if (token) {
          const decodedToken = decode(token) as unknown as JwtPayload;

          if (decodedToken.exp! > Math.floor(Date.now() / 1000) + 60) {
            getNewTokenFromRefresh(refreshToken);
          }
        }
        getNewTokenFromRefresh(refreshToken);
        console.log('refresh toke avail');
        // window.location.replace(`${process.env.REACT_APP_BASE_URL}/login`);
      }
    }
  }, []);

  const setUserFromToken = (token: string) => {
    const user = decode(token) as unknown as JwtPayload;
    console.log('user', user);
    const userObject: IUser = {
      username: user?.name,
      name: user?.name,
      customerId: user['custom:customerId'],
      isAdmin: user['custom:isAdmin'] === 'true' ? true : false,
      email: user?.email,
      groups: user[`cognito:groups`] ? user[`cognito:groups`] : [],
      sub: user.sub!
    };
    setExpiryTime(user.exp!);
    console.log('setting user', userObject);
    setIsAdmin(userObject.isAdmin);
    setUser(userObject);
    if (userObject.groups.includes('developer')) {
      setIsDeveloper(true);
    }
    Cookies.set('user', JSON.stringify(userObject));
  };

  useEffect(() => {
    const localToken = Cookies.get('token');
    if (localToken) {
      setUserFromToken(localToken);
    }
  }, []);

  const getSignIn = async (email: string, password: string, save: boolean) => {
    console.log('email', email);
    console.log('password', password);
    try {
      if (challengeSession) {
        const challengeResult = await cognitoPasswordChallenge(
          email,
          password,
          challengeSession
        );
        if (challengeResult?.AuthenticationResult) {
          setCognitoToken(challengeResult.AuthenticationResult?.IdToken!);
          setToken(challengeResult.AuthenticationResult?.IdToken!);
          Cookies.set('token', challengeResult.AuthenticationResult?.IdToken!);
          setStoredToken(challengeResult.AuthenticationResult?.IdToken!);
          // setStoredRefreshToken(
          //   challengeResult.AuthenticationResult?.RefreshToken!
          // );
          setUserFromToken(challengeResult.AuthenticationResult?.IdToken!);
          setStoredAccessToken(
            challengeResult.AuthenticationResult?.AccessToken!
          );
          Cookies.set(
            'refreshToken',
            challengeResult.AuthenticationResult?.RefreshToken!
          );
          Cookies.set('token', challengeResult.AuthenticationResult?.IdToken!);
        }
        console.log('user form token', user);
        console.log('result from challenge', challengeResult);
      }
      try {
        const cognitoT = await getCognitoLogin(email, password);
        if (cognitoT.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
          setCognitoChallenge(true);
          setChallengeSession(cognitoT?.Session!);
        } else {
          // setCognitoToken(cognitoT.AuthenticationResult?.IdToken!);
          setToken(cognitoT.AuthenticationResult?.IdToken!);
          setUserFromToken(cognitoT.AuthenticationResult?.IdToken!);
          setStoredToken(cognitoT.AuthenticationResult?.IdToken!);
          // setStoredRefreshToken(cognitoT.AuthenticationResult?.RefreshToken!);
          setStoredAccessToken(cognitoT.AuthenticationResult?.AccessToken!);
          Cookies.set(
            'refreshToken',
            cognitoT.AuthenticationResult?.RefreshToken!
          );
          Cookies.set('token', cognitoT.AuthenticationResult?.IdToken!);
          // window.location.replace(
          //   `${process.env.REACT_APP_BASE_URL}/app/landing`
          // );
        }
      } catch (e) {
        const error = e as Error;
        if (error.message === 'Incorrect username or password.') {
          setPasswordUserError(true);
        }
      }
      // setUser(result.data.user);
      // setToken(result.data.token);

      // navigate('/app/landing')
      // console.log('user signed in', result);
    } catch (e) {
      console.log('signin error', e);
    }
  };

  const logout = () => {
    setToken('');
    setUser({} as IUser);
    setStoredToken('');
    setStoredUser('');
    setIsDeveloper(false);
    setIsAdmin(false);
    setStoredAccessToken('');
    Cookies.remove('token');
    Cookies.remove('refreshToken');
    Cookies.remove('user');
    window.location.replace(`${process.env.REACT_APP_BASE_URL}/login`);
  };

  const auth = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };

  const cognitoAuth = {
    headers: {
      Authorization: `Bearer ${token}`
    }
  };

  const value = {
    user,
    token,
    setToken,
    getSignIn,
    logout,
    auth,
    // createAccount,
    isAdmin,
    isDeveloper,
    cognitoAuth,
    cognitoChallenge,
    passwordUserError,
    isExpired
  };

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  );
};
