import * as React from 'react';
import {
  Button,
  Container,
  FileUpload,
  FormField,
  Header,
  Input,
  SpaceBetween,
  Spinner,
  TextContent
} from '@cloudscape-design/components';
import { DocumentContext } from '../../../context/documentContext';
import { createParagraphs } from '../../../helpers/getFileData';
import { useContext, useEffect, useState } from 'react';
import { BaseRow, Importer, ImporterField } from 'react-csv-importer';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { DOCTYPE } from '../../../models/interfaces';
import axios from 'axios';
import { UserContext } from '../../../context/authContext';
import { isEncrypted } from '../../../helpers/determineEncrypted';
interface Props {
  type: string | null;
  projectId: string;
  folderId: string;
}
export const FileSelector = (props: Props) => {
  const [value, setValue] = useState<File[]>([]);
  const [docProcessing, setDocProcessing] = useState<boolean>(false);
  const {
    setFilesToUpload,
    setUploadProcessing,
    uploadProcessing,
    uploadFiles,
    qaOrigin,
    setQAOrigin,
    qaContact,
    setQAContact,
    setMainAlert
  } = useContext(DocumentContext);
  const { auth, user } = useContext(UserContext);
  const navigate = useNavigate();
  const [csvRowsArray, setCSVRowsArray] = useState<any[]>([]);
  const [showFileSelector, setShowFileSelector] = useState<boolean>(true);
  const [showCSVImporter, setShowCSVImporter] = useState<boolean>(false);
  const [csvReady, setCSVReady] = useState<boolean>(false);
  const [fileError, setFileError] = useState<string[]>([]);
  const [allowUpload, setAllowUpload] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleInputChange = async (files: File[]) => {
    setFileError([]);
    if (props.type === DOCTYPE.questionnaire) {
      console.log('e target files', files);
      if (files.length === 0) {
        setFilesToUpload([]);
      }
      setValue(files);
      setDocProcessing(true);

      const fileResults = await createParagraphs(files, props.projectId, props.folderId);
      if (fileResults !== null) {
        console.log('setting uploads', fileResults);
        setFilesToUpload(fileResults);
      }
      setDocProcessing(false);
      setAllowUpload(true);
    } else {
      console.log('e target files', files);
      if (files.length === 0) {
        setFilesToUpload([]);
        setValue([]);
        setFileError([]);

        return;
      }
      const isEncrytedResult = await isEncrypted(files[0]);
      console.log('is encrypted result', isEncrytedResult);
      if (isEncrytedResult) {
        setFileError(['Encrypted']);
      }
      setValue(files);
      setDocProcessing(true);
      // const fileResults = await createParagraphs(files);
      // if (fileResults !== null) {
      //   console.log('setting uploads', fileResults);
      //   setFilesToUpload(fileResults);
      // }
      setDocProcessing(false);
    }
  };

  const handleCSVImport = (fileName: string) => {
    console.log('running handleCSVImport');
    let sentences = csvRowsArray.map((ele) => {
      return `${ele.sentence1 ?? ''} ${ele.sentence2 ?? ''}`;
    });
    let tempObj = {
      sentences: sentences,
      name: fileName,
      uploaded: 'not started'
    };
    setFilesToUpload([tempObj]);
    setAllowUpload(true);
  };

  useEffect(() => {
    setUploadProcessing(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.type === 'questionnaire') {
      setShowCSVImporter(true);
    } else {
      setShowCSVImporter(false);
    }
  }, [props]);
  const handleCSVRow = async (row: BaseRow) => {
    console.log('rowrow', row);
    const tempArray = csvRowsArray;
    tempArray.push(row);
    setCSVRowsArray([...tempArray]);
    console.log('new temp', tempArray);
  };

  const handleCancel = () => {
    const projectQuery = searchParams.get('projectId');
    navigate(`/app/projects?project=${projectQuery}`);
  };

  const hanldeUploadFile = async () => {
    if (value.length === 0) return;
    console.log('Uploading files');
    try {
      setUploadProcessing(true);
      for (let i = 0; i < value.length; i++) {
        // check if file is encrypted
        const docCreateResult = await axios.post(
          `${process.env.REACT_APP_KORU_API}/document?type=addNew`,
          {
            documentFileName: value[i].name,
            documentType: DOCTYPE.analysis,
            project: props.projectId,
            folder: props.folderId
          },
          auth
        );
        // const fileType =
        const presignedUrl = await axios.post(
          `${process.env.REACT_APP_KORU_API}/get-presigned-url?type=upload`,
          {
            // customerId: `${user.customerId}`,
            documentId: `${docCreateResult.data.result._id}.pdf`
          },
          auth
        );
        const curentFile = value[0];
        const fileBody = new FormData();
        fileBody.append(`pdf`, curentFile);
        console.log('presigendUrl', presignedUrl.data);
        const putResult = await axios.put(presignedUrl.data.url, curentFile, {
          headers: {
            'Content-Type': 'application/pdf'
          }
        });
        console.log('put file result', putResult);
        // end of for loop
      }
      setUploadProcessing(false);
      const projectQuery = searchParams.get('projectId');
      navigate(`/app/projects?project=${projectQuery}`);
    } catch (e) {
      console.log('upload error', e);
      const error = e as Error;
      setMainAlert({
        showAlert: true,
        alertType: 'error',
        message: `File Creation Error - ${error.message}`
      });
    }
  };
  useEffect(() => {
    if (value.length > 0 && fileError.length === 0 && props.projectId) {
      setAllowUpload(true);
    } else {
      setAllowUpload(false);
    }
  }, [value, fileError]);

  return (
    <Container
      header={
        <Header
          actions={
            <SpaceBetween direction="horizontal" size={'s'}>
              <Button
                // onClick={() => uploadFiles(props.type)}
                // disabled={!(filesToUpload.length === 1)}
                disabled={!allowUpload}
                // disabled={value.length === 0}
                loading={uploadProcessing}
                onClick={
                  props.type === DOCTYPE.questionnaire
                    ? () => uploadFiles(props.type)
                    : () => hanldeUploadFile()
                }
              >
                Upload
              </Button>
              <Button
                onClick={() => handleCancel()}
                // disabled={!(filesToUpload.length === 1)}
              >
                Cancel
              </Button>
            </SpaceBetween>
          }
        ></Header>
      }
    >
      <SpaceBetween direction="vertical" size="l">
        {/* <FormField label="Folder Name" >
          <Input value={currentFolder} type='text' onChange={({detail}) => setCurrentFolder(detail.value)} />
        </FormField> */}
        {showFileSelector ? (
          <FormField>
            {!docProcessing ? (
              <FileUpload
                onChange={({ detail }) => {
                  // setShowCSVImporter(!showCSVImporter);
                  handleInputChange(detail.value);
                }}
                value={value}
                i18nStrings={{
                  uploadButtonText: (e) => (e ? 'Choose files' : 'Choose file'),
                  dropzoneText: (e) =>
                    e ? 'Drop files to upload' : 'Drop file to upload',
                  removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                  limitShowFewer: 'Show fewer files',
                  limitShowMore: 'Show more files',
                  errorIconAriaLabel: 'Error'
                }}
                showFileLastModified
                showFileSize
                showFileThumbnail
                tokenLimit={3}
                constraintText={
                  props.type === DOCTYPE.questionnaire ? '.docx' : '.pdf'
                }
                multiple={false}
                accept={props.type === DOCTYPE.questionnaire ? '.docx' : '.pdf'}
                fileErrors={fileError}
              />
            ) : (
              <SpaceBetween direction="vertical" size="s">
                <TextContent>
                  <p>Processing Document for upload</p>
                </TextContent>
                <Spinner size="large" />
              </SpaceBetween>
            )}
          </FormField>
        ) : null}

        {showCSVImporter ? (
          <div onClick={() => setShowFileSelector(false)}>
            <Importer
              dataHandler={async (rows, { startIndex }) => {
                // required, may be called several times
                // receives a list of parsed objects based on defined fields and user column mapping;
                // (if this callback returns a promise, the widget will wait for it before parsing more data)

                for (const row of rows) {
                  // console.log('row', row);
                  await handleCSVRow(row);
                }
              }}
              // onStart={() => setShowFileSelector(false)}
              defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
              // restartable={true} // optional, lets user choose to upload another file when import is complete
              onClose={({ file, preview, fields, columnFields }) => {
                handleCSVImport(file.name);
              }}
            >
              <ImporterField name="sentence1" label="Column to Analyse 1" />
              <ImporterField name="sentence2" label="Column to Analyse 2" />
            </Importer>
          </div>
        ) : null}
        {props.type === DOCTYPE.questionnaire ? (
          <SpaceBetween size={'s'}>
            <FormField label="Company">
              <Input
                value={qaOrigin}
                onChange={({ detail }) => setQAOrigin(detail.value)}
              ></Input>
            </FormField>
            <FormField label="Contact Person">
              <Input
                value={qaContact?.person}
                onChange={({ detail }) =>
                  setQAContact({ person: detail.value })
                }
              ></Input>
            </FormField>
          </SpaceBetween>
        ) : null}
      </SpaceBetween>
    </Container>
  );
};
