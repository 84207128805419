import { Grid, SpaceBetween, Button } from '@cloudscape-design/components';
import { decideButton } from '../../../helpers/decideButtonDisabled';
import { filterEnum, Controls } from '../../../models/interfaces';
import { useContext, useEffect, useState } from 'react';
import { DocumentContext } from '../../../context/documentContext';
import './documentControlButtons.css';
import { BurdenAttributionView } from './burdenAttributionView';

export const DocumentControlButtons = (props: { domain: string }) => {
  const { domain } = props;
  const {
    docViewFilter,
    setDocViewFilter,
    controlFilter,
    setControlFilter,
    controlsNavigation,
    controls,
    setSelectedItems
  } = useContext(DocumentContext);
  const [buttnsArray, setButtonArray] = useState<any[]>([]);
  const [showBurdenAttribution, setShowBurdenAttribution] =
    useState<boolean>(false);

  const constantButtons = [
    {
      domainCode: '(CON)',
      button: (
        <div className="button">
          <Button
            onClick={() => {
              setDocViewFilter(filterEnum.location);
              setControlFilter('');
              setSelectedItems([]);
            }}
            variant={
              docViewFilter === filterEnum.location ? 'primary' : 'normal'
            }
          >
            SERVICE LEVEL REQUIREMENTS
          </Button>
        </div>
      )
    },
    {
      domainCode: '(CON)',
      button: (
        <div className="button">
          <Button
            onClick={() => {
              setDocViewFilter(filterEnum.terminiation);
              setControlFilter('');
              setSelectedItems([]);
            }}
            variant={
              docViewFilter === filterEnum.terminiation ? 'primary' : 'normal'
            }
          >
            TERMINATIONS
          </Button>
        </div>
      )
    },
    {
      domainCode: '(CON)',
      button: (
        <div className="button">
          <Button
            onClick={() => {
              setDocViewFilter(filterEnum.Financial_Information);
              setControlFilter('');
              setSelectedItems([]);
            }}
            variant={
              docViewFilter === filterEnum.Financial_Information
                ? 'primary'
                : 'normal'
            }
          >
            FINANCIAL INFORMATION
          </Button>
        </div>
      )
    },
    {
      domainCode: '(RES)',
      button: (
        <div className="button">
          <Button
            onClick={() => {
              setDocViewFilter(filterEnum.location);
              setControlFilter('');
              setSelectedItems([]);
            }}
            variant={
              docViewFilter === filterEnum.location ? 'primary' : 'normal'
            }
          >
            PLACES & LOCATIONS
          </Button>
        </div>
      )
    },
    {
      domainCode: '(RES)',
      button: (
        <div className="button">
          <Button
            onClick={() => {
              setDocViewFilter(filterEnum.burden_attribution);
              setShowBurdenAttribution(true);
              setControlFilter('');
              setSelectedItems([]);
            }}
            variant={
              docViewFilter === filterEnum.burden_attribution
                ? 'primary'
                : 'normal'
            }
          >
            BURDEN ASSIGNMENT
          </Button>
        </div>
      )
    },
    {
      domainCode: '(RES)',
      button: (
        <div className="button">
          <Button
            onClick={() => {
              setDocViewFilter(filterEnum.financial_due_dates);
              setControlFilter('');
              setSelectedItems([]);
            }}
            variant={
              docViewFilter === filterEnum.financial_due_dates
                ? 'primary'
                : 'normal'
            }
          >
            FINANCIAL DUE DATES
          </Button>
        </div>
      )
    },
    {
      domainCode: '(UNC)',
      button: (
        <div className="button">
          <Button
            onClick={() => {
              setDocViewFilter(filterEnum.unclassified_burden);
              setControlFilter('');
              setSelectedItems([]);
            }}
            variant={
              docViewFilter === filterEnum.unclassified_burden
                ? 'primary'
                : 'normal'
            }
          >
            UNCLASSIFIED OBLIGATIONS
          </Button>
        </div>
      )
    }
  ];
  useEffect(() => {
    console.log('controls nav', controlsNavigation);
    const toInclude = Object.keys(controlsNavigation);
    const tempButtonArray = [];
    const thisDomainControls = controls.filter(
      (ele) => ele.domainCode === domain && toInclude.includes(ele.controlName)
    );
    for (const control of thisDomainControls) {
      tempButtonArray.push(
        <div className="button">
          <Button
            onClick={() => {
              setDocViewFilter(filterEnum.control);
              setControlFilter(control.controlName);
              setSelectedItems([]);
            }}
            // disabled={decideButton(cont, controlsNavigation)}
            variant={
              controlFilter === control.controlName ? 'primary' : 'normal'
            }
          >
            {control.controlButton}
          </Button>
        </div>
      );
    }
    const constButtons = constantButtons.filter(
      (ele) => ele.domainCode === domain
    );
    for (const conButton of constButtons) {
      tempButtonArray.push(conButton.button);
    }
    setButtonArray(tempButtonArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlsNavigation, controlFilter, controls, docViewFilter]);

  return (
    <div className="buttonContainer">
      <>
        {!showBurdenAttribution ? <>{buttnsArray}</> : null}
        {showBurdenAttribution ? <BurdenAttributionView /> : null}
      </>
    </div>
  );
};
