import {
  Button,
  Container,
  Header,
  Multiselect,
  SpaceBetween
} from '@cloudscape-design/components';
import { useContext, useEffect, useState } from 'react';
import { DocumentContext } from '../../../context/documentContext';
import { getOwnership } from '../../../helpers/getOwnership';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';

export const BurdenAttributionView = () => {
  const { sentences, burdenAttFilter, setBurdenAttFilter } =
    useContext(DocumentContext);
  const [localPageType, setLocalPageType] = useState<string>('');
  const [filteringValue, setFilteringValue] = useState<OptionDefinition[]>([]);
  const [selectionOptions, setSelectionOptions] = useState<OptionDefinition[]>(
    []
  );

  useEffect(() => {
    if (sentences.length > 0) {
      const ownershipList = getOwnership(sentences);
      console.log('ownership list', ownershipList);
      if (ownershipList.length > 0) {
        const tempList = ownershipList.reduce(
          (acc, curr) => {
            acc.optionList.push({ label: curr, value: curr });
            return acc;
          },
          { optionList: [] } as {
            optionList: { label: string; value: string }[];
          }
        );
        setSelectionOptions(tempList.optionList);
        setFilteringValue([]);
        // setFilteringValue(tempList.optionList);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sentences]);

  useEffect(() => {
    const tempStringArray = [];
    for (const value of filteringValue) {
      tempStringArray.push(value.value!);
    }
    if (tempStringArray.length > 0) {
      setBurdenAttFilter(tempStringArray);
    }
  }, [filteringValue, setBurdenAttFilter]);

  return (
    <div style={{ overflowY: 'scroll' }}>
      <SpaceBetween direction="vertical" size="s">
        <Multiselect
          placeholder="Please select assignments"
          selectedOptions={filteringValue}
          options={selectionOptions}
          onChange={({ detail }) => {
            console.log('detail', detail);
            setFilteringValue([...detail.selectedOptions]);
          }}
          expandToViewport
        />
      </SpaceBetween>
    </div>
  );
};
