import { Container } from '@cloudscape-design/components';
import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DocumentContext } from '../../../context/documentContext';
import { CircleSpinner } from '../testSpinner';
import axios from 'axios';
import { UserContext } from '../../../context/authContext';
import { documentTableItem, sentenceItem } from '../../../models/interfaces';
import { isCurrentFiltered } from '../../../helpers/getRowColor';
import { SetUserMFAPreferenceRequestFilterSensitiveLog } from '@aws-sdk/client-cognito-identity-provider';

interface AggregatedDocumentItem {
  [key: string]: {
    sentences: sentenceItem[];
    document: documentTableItem;
  };
}
export const AggregatedDocumentView = () => {
  const {
    getAggregatedViewSentences,
    aggSentencesLoading,
    sentences,
    selectedItems,
    setSelectedItems,
    docViewFilter,
    burdenAttFilter,
    controlFilter,
    setControlsNavigation,
    setAggSentencesLoading
  } = useContext(DocumentContext);
  const { auth, token } = useContext(UserContext);
  const [queryParameters] = useSearchParams();
  const [filteredSentences, setFilteredSentences] = useState<sentenceItem[]>(
    []
  );
  const [localReducedSentences, setLocalReducedSentences] =
    useState<AggregatedDocumentItem>({});

  const getSetup = () => {
    const testValue = queryParameters.get('documentId')?.split(',');
    if (testValue) {
      console.log('test value', testValue);
      getAggregatedViewSentences(testValue);
      getBurdensByDoc();
    }
  };
  useEffect(() => {
    getSetup();
  }, []);

  useEffect(() => {
    getSetup();
  }, [token]);

  const getBurdensByDoc = async () => {
    try {
      const testValue = queryParameters.get('documentId')?.split(',');
      if (testValue) {
        console.log('test value', testValue);
        const burdensResult = await axios.post(
          `${process.env.REACT_APP_KORU_API}/document?type=documentInfo`,
          {
            documentIds: testValue
          },
          auth
        );
        setControlsNavigation(burdensResult.data.result.controls);
      }
    } catch (e) {
      const error = e as Error;
    }
  };

  useEffect(() => {
    console.log('control filter', controlFilter);
    console.log('burden att filter', burdenAttFilter);
    const accumulator: AggregatedDocumentItem = {};
    console.log('sentence length', sentences.length);
    if (
      docViewFilter === null &&
      controlFilter === '' &&
      burdenAttFilter.length === 0
    ) {
      console.log('resetting to all sentences');
      const filteredReduced = sentences.reduce((acc, curr) => {
        if (!acc[curr.documentId_string]) {
          acc[curr.documentId_string] = {
            sentences: [curr],
            document: curr.documentId
          };
        } else {
          acc[curr.documentId_string].sentences.push(curr);
        }
        return acc;
      }, accumulator);
      setLocalReducedSentences(filteredReduced);
    } else {
      const filteredReduced = sentences.reduce((acc, curr) => {
        if (
          isCurrentFiltered(curr, docViewFilter, burdenAttFilter, controlFilter)
        ) {
          console.log('adding sentenceId', curr._id);
          if (!acc[curr.documentId_string]) {
            acc[curr.documentId_string] = {
              sentences: [curr],
              document: curr.documentId
            };
          } else {
            acc[curr.documentId_string].sentences.push(curr);
          }
          return acc;
        } else {
          return acc;
        }
      }, accumulator);
      setLocalReducedSentences(filteredReduced);
      console.log()
    }
  }, [sentences, controlFilter, docViewFilter, burdenAttFilter]);

  return (
    <Container header={<h1>Aggregated Document View</h1>}>
      {aggSentencesLoading && <CircleSpinner />}
      {Object.keys(localReducedSentences).map((ele) => {
        return (
          <>
            <h1>{localReducedSentences[ele].document.documentFileName}</h1>
            {localReducedSentences &&
              localReducedSentences[ele]?.sentences.map((sentence) => {
                return (
                  <p
                    style={{
                      outline: '1px solid black',
                      borderRadius: '5px',
                      padding: '1% 1%',
                      backgroundColor: 'lightGrey',
                      marginLeft: '5%',
                      width: '95%'
                    }}
                    onClick={() => {
                      setSelectedItems([sentence]);
                    }}
                  >
                    {sentence.text}
                  </p>
                );
              })}
          </>
        );
      })}
    </Container>
  );
};
