import { useState, useEffect, useContext, useCallback } from 'react';
import {
  Table,
  Container,
  Button,
  SpaceBetween,
  Header,
  Box,
  Spinner,
  ProgressBar,
  Icon,
  Modal,
  Select,
  Pagination,
  ButtonDropdown
} from '@cloudscape-design/components';
import LoadingBar from '@cloudscape-design/chat-components/loading-bar';

// models
import {
  documentTableItem,
  FolderItem,
  KBOptions,
  Projects
} from '../models/interfaces';
import { useCollection } from '@cloudscape-design/collection-hooks';
// helpers
// dummy data
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DocumentContext } from '../context/documentContext';
import { UserContext } from '../context/authContext';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { MoveDocumentToProject } from './Components/projectMoveForm';
import { ProjectContext } from '../context/projectContext';

export const DocumentTableView = () => {
  const navigate = useNavigate();
  const {
    addDocToKnowledgeBase,
    resetDocumentState,
    allDocuments,
    setAllDocuments
  } = useContext(DocumentContext);
  const { selectedProjects, setSelectedProjects, selectedFolder } =
    useContext(ProjectContext);
  const { auth, cognitoAuth } = useContext(UserContext);
  // state
  const [selectedItemsLocal, setSelectedItemsLocal] = useState<
    documentTableItem[]
  >([]);
  // const [allDocuments, setAllDocuments] = useState<documentTableItem[]>([]);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [showDocTypeModal, setShowDocTypeModal] = useState<boolean>(false);
  const [docTypeValue, setDocTypeValue] = useState<OptionDefinition>({
    label: 'No Selection',
    value: 'NS'
  });
  const [kbLoading, setKBLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { deleteDocument } = useContext(DocumentContext);
  const [deleteCheckModla, setDeleteCheckModal] = useState<boolean>(false);
  const [addToKBModal, setAddToKBModal] = useState<boolean>(false);
  const [docsLoading, setDocLoading] = useState<boolean>(false);
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(1);
  const [currentStorage, setCurrentStorage] = useState<number>(0);
  const [filteredByProject, setFilteredByProject] = useState<
    documentTableItem[]
  >([]);
  const [moveModal, setMoveModal] = useState<boolean>(false);
  const [folderList, setFolderList] = useState<OptionDefinition>({
    label: '',
    value: ''
  });
  const [moveLoading, setMoveLoading] = useState<boolean>(false);

  const getAllDocs = async () => {
    setDocLoading(true);

    const docs = await axios.post(
      `${process.env.REACT_APP_KORU_API}/document?type=all`,
      {},
      auth
    );
    if (docs.data.result) {
      setAllDocuments(docs.data.result);
    }
    setDocLoading(false);
  };

  const getDocContinous = (allDocumentsLocal: documentTableItem[]) => {
    const docList = allDocumentsLocal.map((ele) => ele.status);
    if (!docList.includes('Analyzing')) {
      return;
    } else {
      getAllDocs();
    }
  };

  useEffect(() => {
    getAllDocs();
    resetDocumentState();
    getStorageSize();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('use effect ', selectedFolder);
    if (!selectedFolder?._id) {
      setFilteredByProject([]);
    } else {
      const filtered = allDocuments.filter(
        (ele) => ele?.folder === selectedFolder._id
      );
      console.log('filtered docs', filtered);
      setFilteredByProject(filtered);
    }
  }, [allDocuments, selectedFolder]);

  const getStorageSize = async () => {
    try {
      const storage = await axios.post(
        `${process.env.REACT_APP_KORU_API}/document?type=getGBSize`,
        {},
        auth
      );
      console.log('result = ', storage.data.result);
      const convertToGB = storage.data.result.totalSize / 1073741824 / 20;
      // const convertToGB = (10 * 1073741824) / 20;
      setCurrentStorage(convertToGB);
    } catch (e) {
      const error = e as Error;
      console.log('error in get storage', error.message);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      getDocContinous(allDocuments);
    }, 15000);
    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allDocuments]);

  const handleDelete = async () => {
    const tempSelectedItems = selectedItemsLocal;
    if (selectedItemsLocal.length < 1) return;
    setDeleting(true);
    for (let i = 0; i < tempSelectedItems.length; i++) {
      const result = await deleteDocument(tempSelectedItems[i]);
      console.log('result from delete', result);
      if (result._id) {
        const tempAllDocs = allDocuments;
        const restDocs = tempAllDocs.filter(
          (ele) => ele._id !== tempSelectedItems[i]._id
        );
        setAllDocuments(restDocs);
      }
    }
    setSelectedItemsLocal([]);
    setDeleting(false);
    setDeleteCheckModal(false);
  };

  const handleAddToKB = async () => {
    setKBLoading(true);
    const result = await addDocToKnowledgeBase(
      selectedItemsLocal[0]._id,
      docTypeValue.value!
    );
    if (result.status === 200) {
      setDocTypeValue({ label: 'No Selection', value: 'NS' });
      setAddToKBModal(false);
      setSelectedItemsLocal([]);
      getAllDocs();
    } else {
      setErrorMessage('Error adding to KB');
    }
  };

  const handleNavigateUpload = () => {
    switch (true) {
      case selectedProjects[0].projectType === 'workspace':
        navigate(
          `/app/upload?projectId=${selectedProjects[0]._id}&folder=${selectedFolder._id}`
        );
        break;
      case selectedProjects[0].projectType === 'information_request':
        navigate(
          `/app/upload?projectId=${selectedProjects[0]._id}&type=questionnaire&folder=${selectedFolder._id}`
        );
        break;
    }
  };

  const handleMoveProject = async () => {
    try {
      setMoveLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_KORU_API}/document?type=moveProject`,
        {
          _id: selectedItemsLocal[0]._id,
          newFolder: folderList.value
        },
        auth
      );
      console.log('response', response.data.result);
      getAllDocs();
      setMoveLoading(false);
      setMoveModal(false);
    } catch (e) {
      const error = e as Error;
      console.log('update error ', error.message);
    }
  };

  const handleActionButton = (e: any) => {
    console.log('e in action button', e.detail.id);
    switch (true) {
      case e.detail.id === 'moveFolder':
        setMoveModal(true);
        break;
      case e.detail.id === 'deleteSelected':
        setDeleteCheckModal(true);
        break;
      case e.detail.id === 'addKB':
        setAddToKBModal(true);
        break;
      case e.detail.id === 'reports':
        navigate(`/app/reports?documentId=${selectedItemsLocal[0]._id}`);
        break;
      case e.detail.id === 'addDocument':
        handleNavigateUpload();
        break;
    }
  };

  const emptyState = useCallback(() => {
    if (docsLoading && allDocuments.length === 0) {
      return <Spinner></Spinner>;
    } else {
      return (
        <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
          <SpaceBetween size="m">
            <b>No resources</b>
            <Button
              onClick={() => handleNavigateUpload()}
              disabled={!selectedFolder?._id ? true : false}
            >
              Upload document to start
            </Button>
          </SpaceBetween>
        </Box>
      );
    }
  }, [allDocuments, docsLoading, handleNavigateUpload, selectedFolder]);

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps
  } = useCollection(filteredByProject, {
    filtering: {
      empty: <Box>Nothing</Box>,
      noMatch: <Box>No matches</Box>
    },
    pagination: { pageSize: 8 },
    sorting: {},
    selection: {}
  });
  const { selectedItems } = collectionProps;
  useEffect(() => {
    const tempSelect = JSON.stringify(selectedItems);
    const parsedTempSelect = JSON.parse(tempSelect);
    setSelectedItemsLocal(parsedTempSelect);
  }, [selectedItems]);

  return (
    <>
      <Container
        header={
          <Header
            variant="h1"
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <ButtonDropdown
                  items={[
                    {
                      text: 'Move Folder',
                      id: 'moveFolder',
                      disabled: !(selectedItems && selectedItems.length > 0)
                    },
                    {
                      text: 'Delete Document',
                      id: 'deleteSelected',
                      disabled: !(selectedItems && selectedItems.length > 0)
                    },
                    {
                      text: 'Make Searchable',
                      id: 'addKB',
                      disabled: !(selectedItems && selectedItems.length > 0)
                    },
                    {
                      text: 'Reports',
                      id: 'reports',
                      disabled: !(selectedItems && selectedItems.length > 0)
                    },
                    {
                      text: 'Add Document',
                      id: 'addDocument',
                      disabled: !selectedFolder?._id
                    }
                  ]}
                  onItemClick={(item) => handleActionButton(item)}
                >
                  Actions
                </ButtonDropdown>
                {/* {selectedItemsLocal.length > 0 && ( */}
                <>
                  <Button
                    variant="primary"
                    disabled={!(selectedItemsLocal.length > 0)}
                    onClick={() => {
                      if (selectedItemsLocal[0].documentType === 'analysis') {
                        navigate(
                          // `/app/base?documentId=${selectedItemsLocal[0]._id}&projectId=${selectedProjects[0]._id} `
                          `/app/base?documentId=${selectedItems?.map((ele) => ele._id)}&projectId=${selectedProjects[0]._id}&type=${selectedItems?.length === 1 ? 'single' : 'aggregated'}`
                        );
                      } else {
                        navigate(
                          `/app/questionnaire?documentId=${selectedItemsLocal[0]._id}&projectId=${selectedProjects[0]._id}`
                        );
                      }
                    }}
                  >
                    View Document
                  </Button>
                </>
                {/* )} */}
                {/* <Button onClick={() => handleNavigateUpload()}>
                  Add Document to Folder
                </Button> */}
                <ProgressBar
                  value={currentStorage}
                  additionalInfo="Storage Used"
                  // description="Progress bar description"
                  // label="Progress bar label"
                />
              </SpaceBetween>
            }
          >
            Documents
          </Header>
        }
      >
        {/* <div style={{ overflowY: 'scroll' }}> */}
        <Table
          // onSelectionChange={({ detail }) =>
          //   setSelectedItemsLocal(detail.selectedItemsLocal)
          // }
          {...collectionProps}
          contentDensity="compact"
          items={items}
          selectionType="multi"
          pagination={<Pagination {...paginationProps} />}
          columnDefinitions={[
            {
              id: 'title',
              header: 'Document Tile',
              cell: (item: documentTableItem) => item.documentFileName,
              sortingField: 'documentFileName',
              maxWidth: '400px'
            },
            {
              id: 'docStatus',
              header: 'Document Status',
              maxWidth: '200px',
              cell: (item: documentTableItem) => {
                if (item.status === 'analyzed') {
                  return (
                    <SpaceBetween size="l" direction="horizontal">
                      <Box>Analysis Complete</Box>
                      <ProgressBar status="success" />
                    </SpaceBetween>
                  );
                }
                if (item.status === 'Analyzing') {
                  return <LoadingBar variant="gen-ai" />;
                }
                if (item.status === 'Processing Error') {
                  return (
                    <SpaceBetween size="l" direction="horizontal">
                      <Box>Processing Error</Box>
                      <Icon name="status-negative"></Icon>
                    </SpaceBetween>
                  );
                }
                if (item.status === 'Published') {
                  return (
                    <SpaceBetween size="l" direction="horizontal">
                      <Box>Published</Box>
                      <ProgressBar status="success" />
                    </SpaceBetween>
                  );
                }
              },
              sortingField: 'status'
            },
            {
              id: 'docType',
              header: 'Document Type',
              cell: (item: documentTableItem) => item.documentType,
              sortingField: 'documentType',
              maxWidth: '200px'
            },
            {
              id: 'numPages',
              header: 'No Pages',
              cell: (item: documentTableItem) => item.NumberOfPages,
              maxWidth: '200px'
            }
          ]}
          loadingText="Loading resources"
          empty={emptyState()}
          selectedItems={selectedItems}
        />
        {/* </div> */}
      </Container>
      <Modal
        visible={showDocTypeModal}
        header={<Header>Select Document Type</Header>}
        size="large"
        onDismiss={() => {
          setShowDocTypeModal(false);
        }}
      >
        {/* <SpaceBetween size={'s'} direction="vertical">
          {!kbLoading ? (
            <>
              <RadioGroup
                onChange={({ detail }) => setDocTypeValue(detail.value)}
                value={docTypeValue}
                items={[
                  { value: 'Organizational', label: 'Organizational' },
                  { value: 'Customer Contract', label: 'Customer Contract' },
                  { value: 'Vendor Contract', label: 'Vendor Contract' }
                ]}
              />
              <Button
                disabled={docTypeValue === '' ? true : false}
                onClick={() => handleAddToKB()}
              >
                Add to Knowledge Base
              </Button>
            </>
          ) : (
            <Spinner />
          )}
          {errorMessage ? <Box>{errorMessage}</Box> : null}
        </SpaceBetween> */}
      </Modal>
      <Modal
        visible={deleteCheckModla}
        header={<Header>Delete Selected Document</Header>}
        onDismiss={() => setDeleteCheckModal(false)}
      >
        <SpaceBetween size={'s'} direction="horizontal">
          <Button onClick={() => setDeleteCheckModal(false)}>Cancel</Button>
          <Button onClick={() => handleDelete()} loading={deleting}>
            Delete
          </Button>
        </SpaceBetween>
      </Modal>
      <Modal
        visible={addToKBModal}
        header={<Header>Make the Document Searchable</Header>}
        onDismiss={() => setAddToKBModal(false)}
        footer={
          <SpaceBetween size={'s'} direction="horizontal">
            <Button onClick={() => setAddToKBModal(false)}>Cancel</Button>
            <Button onClick={() => handleAddToKB()} loading={deleting}>
              Add Document
            </Button>
          </SpaceBetween>
        }
      >
        <Select
          selectedOption={docTypeValue}
          options={KBOptions}
          onChange={({ detail }) => setDocTypeValue(detail.selectedOption)}
          expandToViewport
        ></Select>
      </Modal>
      <Modal
        visible={moveModal}
        header={<Header>Move Document</Header>}
        onDismiss={() => setMoveModal(false)}
        footer={
          <SpaceBetween size={'s'} direction="horizontal">
            <Button onClick={() => setMoveModal(false)}>Cancel</Button>
            <Button onClick={() => handleMoveProject()} loading={moveLoading}>
              Move Document
            </Button>
          </SpaceBetween>
        }
      >
        <MoveDocumentToProject
          folderList={folderList}
          setFolderList={setFolderList}
          filteredFolders={selectedProjects[0].folders}
        />
      </Modal>
    </>
  );
};
