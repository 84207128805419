import {
  Box,
  Button,
  Container,
  Grid,
  Header,
  SpaceBetween,
  TextContent
} from '@cloudscape-design/components';
import { Navigate, useNavigate } from 'react-router-dom';

export const MainPage = () => {
  const navigate = useNavigate();
  return (
    <Container
      header={
        <TextContent>
          <h2>Welcome!</h2>
        </TextContent>
      }
    >
      <SpaceBetween direction="vertical" size="xxl">
        <TextContent>
          <p style={{ fontSize: '1.3em' }}>
            The Koru Compliance Platform is designed to simplify your compliance
            management process. Koru helps you discover and categorize
            compliance-related content in both external party documents like
            RFPs, Regulations, Frameworks, Contracts, as well as in your
            organization's existing documentation such as procedures, policies,
            and existing contracts.
          </p>
        </TextContent>
        <Box textAlign="left">
          <TextContent>
            <h3>Key Features</h3>
          </TextContent>
        </Box>
        <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
          <Container
            header={
              <Box textAlign="center">
                <Button
                  onClick={() => navigate('/app/upload')}
                  variant="primary"
                >
                  Document Analysis
                </Button>
              </Box>
            }
            fitHeight
          >
            <TextContent>
              <p style={{ fontSize: '1.3em' }}>
                Koru scans a variety of documents, such as contracts, policies,
                procedures, and regulatory frameworks, to discover
                compliance-related content. This is useful for identifying
                potential compliance risks and obligations that may otherwise go
                unnoticed.
              </p>
            </TextContent>
          </Container>
          <Container
            fitHeight
            header={
              <Box textAlign="center">
                <Button
                  onClick={() => navigate('/app/documents')}
                  variant="primary"
                  
                >
                  Compliance Information Classification
                </Button>
              </Box>
            }
          >
            <TextContent>
              <p style={{ fontSize: '1.3em' }}>
                Koru classifies the discovered compliance information into
                various categories such as compliance topics and obligations for
                easy reference.
              </p>
            </TextContent>
          </Container>
          <Container
            // eslint-disable-next-line react/jsx-no-undef
            header={
              <Box textAlign="center">
                <Button
                  onClick={() => navigate('/app/splash')}
                  variant="primary"
                >
                  Knowledge Base Creation
                </Button>
              </Box>
            }
            fitHeight
          >
            <TextContent>
              <p style={{ fontSize: '1.3em' }}>
                All Koru analyzed information can be transformed into a your own
                compliance knowledge base. Content is fully categorized,
                indexed, and tagged with compliance metadata for future
                reference by Koru. This enables you to trace new obligations to
                previous historical information such as responses and stay
                consistent in your compliance activities.
              </p>
            </TextContent>
          </Container>
        </Grid>
        {/* <Container
          header={<Header variant="h3">How to Get Started with Koru</Header>}
        >
          Upload Documents: Navigate to the 'Upload Document' page to upload the
          documents you'd like to analyze. <br />
          Analyze: Go to the 'Document Analysis' page to start the analysis.
          <br /> Review: Check the results on the 'Document Analysis' page and
          make any necessary adjustments. <br />
          Knowledge Base: Access your categorized, indexed, and referenceable
          knowledge base anytime for future reference. <br />
          Reports: Navigate to the 'Reports' page to view a list of your
          uploaded documents and create reports based on your analysis.
        </Container> */}
      </SpaceBetween>
    </Container>
  );
};
