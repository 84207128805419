import { lazy, Suspense, useEffect } from 'react';
import {
  AppLayout,
  Box,
  Button,
  Container,
  ContentLayout,
  ExpandableSection,
  Grid,
  Header,
  Link,
  SpaceBetween,
  Spinner,
  SplitPanel
} from '@cloudscape-design/components';
// import { PDFView } from './Components/pdfViewer/pdfViewer';
import { KnowledgeBaseWrapper } from './Components/knowledgeBaseWrapper';
import { WorkWithDoc } from './Components/workWithDocument/workWithDoc';
import { useContext, useState } from 'react';
import { DocumentContext } from '../context/documentContext';
import { filterEnum, Controls } from '../models/interfaces';
import { decideButton } from '../helpers/decideButtonDisabled';
import { BurdenAttributionView } from './Components/workWithDocument/burdenAttributionView';
import { CircleSpinner } from './Components/testSpinner';
import { DocumentControlButtons } from './Components/workWithDocument/documentControlButtons';
import { UserContext } from '../context/authContext';
import axios from 'axios';
import { DocumentMapper } from './Components/workWithDocument/DocumentMapper';
import { ProjectContext } from '../context/projectContext';
import { useSearchParams } from 'react-router-dom';
import { ParentDocumentMapper } from './Components/workWithDocument/ParentDocumentMapper';
import { AggregatedDocumentView } from './Components/aggregatedViews/AggregatedDocumentView';
import { ButtonWrapper } from './Components/workWithDocument/buttonWrapper';

// import PDFView from './Components/pdfViewer/pdfViewer';

export const DocumentLayoutViewer = () => {
  const {
    docViewFilter,
    setDocViewFilter,
    updateRedline,
    updateFocus,
    selectedItems,
    controlFilter,
    setControlFilter,
    controlsNavigation,
    updateStarred,
    setBurdenAttFilter,
    currentDoc
  } = useContext(DocumentContext);
  const { auth, isDeveloper } = useContext(UserContext);
  const { mainProjects, selectedProjects, getAllProjects } =
    useContext(ProjectContext);
  const [showControlButtons, setShowControlButtons] = useState<boolean>(false);
  const [showBurdenAttribution, setShowBurdenAttribution] =
    useState<boolean>(false);
  const [showPDF, setShowPDF] = useState<boolean>(false);
  const [domainResult, setDomainResult] = useState<any[]>([]);
  const [controlResult, setControlResult] = useState<any[]>([]);
  const [customerDomain, setCustomerDomain] = useState<any[]>([]);
  const [custmerControl, setCustomerControls] = useState<any[]>([]);
  const [queryParameters] = useSearchParams();
  const PDFView = lazy(() => import('./Components/pdfViewer/pdfViewer'));

  const getDomainForSentence = async () => {
    try {
      setDomainResult([]);
      setControlResult([]);
      setCustomerControls([]);
      const result = await axios.post(
        `${process.env.REACT_APP_KORU_API}/sentenceVector?type=getDomain`,
        { sentence: selectedItems[0] },
        auth
      );
      const resultByCustomer = await axios.post(
        `${process.env.REACT_APP_KORU_API}/sentenceVector?type=getDomainsByCustmer`,
        { sentence: selectedItems[0] },
        auth
      );
      console.log('resulyByCustomer', resultByCustomer);
      console.log('result from domains', result.data.results);
      setCustomerControls(resultByCustomer.data.results);
      const passDomains = result.data.results.map(
        (ele: { domain: any; score: any }) => `${ele.domain} - ${ele.score}`
      );
      const passControl = result.data.results.map(
        (ele: { control: any; score: any }) => `${ele.control} - ${ele.score}`
      );

      const uniqueDomain = Array.from(new Set(passDomains));
      const uniqueControl = Array.from(new Set(passControl));

      setDomainResult(uniqueDomain);
      setControlResult(uniqueControl);
    } catch (e) {
      console.log('e', e);
    }
  };
  const handleTest = async () => {
    const resultByCustomer = await axios.post(
      `${process.env.REACT_APP_KORU_API}/sentenceVector?type=specifyScope`,
      { sentence: selectedItems[0] },
      auth
    );
    console.log('test result', resultByCustomer);
  };

  const getSetup = async () => {
    try {
      const projectId = queryParameters.get('projectId');
      if (projectId) {
        getAllProjects(projectId);
      } else {
        console.log('no project id present.');
      }
    } catch (e) {
      console.log('Errror on load document viewer', e);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box>{currentDoc.documentFileName}</Box>
      <div
        style={{
          height: '82vh',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flexStart',
          justifyContent: 'center',
          marginTop: '2%',
          width: '95vw',
          paddingLeft: '2%',
          paddingTop: '1%',
          overflowY: 'scroll'
        }}
      >
        <div
          style={{
            width: '48vw',
            height: '100%',
            overflowY: 'scroll',
            scrollbarWidth: 'none',
            outline: '1px solid gray',
            borderRadius: '13px',
            marginRight: '1%'
          }}
        >
          {queryParameters.get('type') !== 'aggregated' ? (
            <Suspense fallback={<CircleSpinner />}>
              <PDFView />
            </Suspense>
          ) : (
            <AggregatedDocumentView />
          )}
        </div>
        {/* Right side of view */}
        <div
          style={{
            // height: '75vh',
            // border: '1px solid black',
            // paddingLeft: '2%',
            width: '40vw',
            minHeight: '75vh',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'scroll',
            scrollbarWidth: 'none'
          }}
        >
          <ExpandableSection
            defaultExpanded
            headerText="Explore Document"
            variant="container"
            headerActions={
              <Header
                actions={
                  <SpaceBetween size={'xxs'} direction="horizontal">
                    <Button
                      onClick={() => {
                        setDocViewFilter(null);
                        setShowControlButtons(false);
                        setControlFilter('');
                        setShowBurdenAttribution(false);
                        setBurdenAttFilter([]);
                      }}
                    >
                      Reset
                    </Button>
                    <Button
                      onClick={() => {
                        setDocViewFilter(filterEnum.starred);
                        setShowControlButtons(false);
                        setControlFilter('');
                        setShowBurdenAttribution(false);
                      }}
                    >
                      Starred
                    </Button>
                  </SpaceBetween>
                }
              >
                {/* <h2>Explore Document</h2> */}
              </Header>
            }
            // fitHeight
          >
            <ButtonWrapper />
          </ExpandableSection>
          <ExpandableSection
            headerText="Work with Document"
            variant="container"
            headerActions={
              <Header
                actions={
                  <SpaceBetween size={'xxs'} direction="horizontal">
                    <Button
                      iconName={
                        selectedItems[0]?.starred ? 'star-filled' : 'star'
                      }
                      variant="icon"
                      onClick={() => updateStarred()}
                    />
                    <Button
                      onClick={() => updateFocus()}
                      disabled={selectedItems.length > 0 ? false : true}
                      variant={selectedItems[0]?.burden ? 'primary' : 'normal'}
                    >
                      Focus
                    </Button>
                    <Button
                      onClick={() => updateRedline()}
                      disabled={selectedItems.length > 0 ? false : true}
                      variant={
                        selectedItems[0]?.redlined ? 'primary' : 'normal'
                      }
                    >
                      Redline
                    </Button>
                  </SpaceBetween>
                }
              >
                {/* <h2>Work with Document</h2> */}
              </Header>
            }
            // fitHeight
          >
            <div style={{ minHeight: '30vh', width: '100%' }}>
              <WorkWithDoc />
            </div>
          </ExpandableSection>
          {/* <div
          style={{
            minHeight: '45vh',
            width: '100%',
            overflow: 'scroll',
            scrollbarWidth: 'none'
          }}
        > */}
          {/* <Container header={<h2>Research</h2>} fitHeight> */}
          <KnowledgeBaseWrapper />
          <DocumentMapper />
          {selectedItems[0]?.mappedParentSentences &&
            selectedItems[0]?.mappedParentSentences.length > 0 && (
              <ParentDocumentMapper />
            )}
          {isDeveloper ? (
            <ExpandableSection headerText="Dev mode">
              <SpaceBetween size={'xxs'}>
                <Button onClick={() => getDomainForSentence()}>
                  Get Domains
                </Button>
                <Button onClick={() => handleTest()}>test button</Button>
                <p>Domains</p>
                {domainResult?.length > 0
                  ? domainResult.map((ele) => <p>{ele}</p>)
                  : null}
                <p>Controls</p>
                {controlResult?.length > 0
                  ? controlResult.map((ele) => <p>{ele}</p>)
                  : null}
                {custmerControl?.length > 0
                  ? custmerControl.map((ele) => {
                      return (
                        <div>
                          domain: {ele.predicted_label_domain}
                          <br />
                          conrol: {ele.predicted_label_control}
                          <br />
                          score: {ele.score}
                        </div>
                      );
                    })
                  : null}
              </SpaceBetween>
            </ExpandableSection>
          ) : null}
        </div>
      </div>
    </div>
  );
};
