import {
  Alert,
  Button,
  Container,
  Header,
  SpaceBetween
} from '@cloudscape-design/components';
import { useContext, useEffect, useState } from 'react';
import { IUser } from '../../models/interfaces';
import axios from 'axios';
import { UserContext } from '../../context/authContext';
import { UserRow } from './ManageUserRow';

export const ManageUsers = () => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [alert, setAlert] = useState<{
    showAlert: boolean;
    message: string;
    type: 'error' | 'success';
  }>({
    showAlert: false,
    message: '',
    type: 'success'
  });
  const { auth } = useContext(UserContext);

  const getUsers = async () => {
    const results = await axios.post(
      `${process.env.REACT_APP_KORU_API}/user?type=getAll`,
      {},
      auth
    );
    if (results.status === 200) {
      const sortedList = results.data.list.sort(
        (a: { email: string }, b: { email: string }) =>
          a.email.localeCompare(b.email)
      );
      setUsers(sortedList);
    }
  };
  //TODO add function to lambda
  // const forcePasswordReset = async (id: string, password: string, isAdmin: boolean) => {
  //   console.log('runnning reset password')
  //   const results = await axios.put(
  //     `${process.env.REACT_APP_API}/resetpassword`,
  //     { userToReset: id, newPassword: password, isAdmin: isAdmin },
  //     auth
  //   );
  //   if(results.status === 204) {
  //     console.log('result from password reset', results)
  //   } else {
  //     console.log('error', results)
  //   }
  // };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addUser = () => {
    const tempUser = {
      sub: '',
      username: '',
      name: '',
      password: null,
      customerId: '',
      isAdmin: false,
      email: '',
      groups: []
    };
    const tempUserArray = users;
    tempUserArray.push(tempUser);
    setUsers([...tempUserArray]);
  };
  const saveUserSignup = async (
    email: string,
    name: string,
    password: string,
    isAdmin: boolean
  ) => {
    try {
      const results = await axios.post(
        `${process.env.REACT_APP_KORU_API}/user?type=addNew`,
        { email, name, password, isAdmin: `${isAdmin}` },
        auth
      );
      if (results.data.response.$metadata.httpStatusCode === 200) {
        console.log('result from create user', results);
        setAlert({ showAlert: true, message: 'User created', type: 'success' });
      } else {
        setAlert({ showAlert: true, message: 'User created', type: 'success' });
        console.log('error', results);
      }
    } catch (e) {
      const error = e as Error;
      setAlert({ showAlert: true, message: error.message, type: 'error' });
    }
  };

  const updateUser = async (
    thissub: string,
    name: string,
    email: string,
    isAdmin: boolean
  ) => {
    console.log('this sub in update', thissub);
    try {
      const attributesArray = [
        { Name: 'custom:isAdmin', Value: `${isAdmin ? 'true' : 'false'}` },
        { Name: 'name', Value: name },
        { Name: 'email', Value: email }
      ];
      const results = await axios.post(
        `${process.env.REACT_APP_KORU_API}/user?type=update`,
        { username: thissub, UserAttributes: attributesArray },
        auth
      );
      getUsers();
      setAlert({ showAlert: true, message: 'User updated', type: 'success' });
      console.log(results);
    } catch (e) {
      console.log('error from update', e);
      setAlert({
        showAlert: true,
        message: 'Error updating User',
        type: 'error'
      });
    }
  };
  const updatePassword = async (sub: string, newPassword: string) => {
    try {
      const results = await axios.post(
        `${process.env.REACT_APP_KORU_API}/user?type=updatePassword`,
        { username: sub, newPassword },
        auth
      );
      setAlert({ showAlert: true, message: 'Password Reset', type: 'success' });
      console.log(results);
    } catch (e) {
      setAlert({
        showAlert: true,
        message: 'Error resetting password',
        type: 'error'
      });
    }
  };
  return (
    <Container
      header={
        <Header
          actions={
            <SpaceBetween direction="horizontal" size={'s'}>
              {/* <Button>Save</Button> */}
              <Button onClick={() => addUser()}>Add User</Button>
            </SpaceBetween>
          }
        >
          Manage Users
        </Header>
      }
    >
      {alert.showAlert ? (
        <Alert
          type={alert.type}
          dismissible
          onDismiss={() =>
            setAlert({ showAlert: false, message: '', type: 'success' })
          }
        >
          {alert.message}
        </Alert>
      ) : null}
      <SpaceBetween size="l">
        {users.length > 0
          ? users.map((userRow, index) => {
              return (
                <UserRow
                  key={index}
                  user={userRow}
                  // forcePasswordReset={forcePasswordReset}
                  saveUser={saveUserSignup}
                  updateUser={updateUser}
                  updatePassword={updatePassword}
                />
              );
            })
          : null}
      </SpaceBetween>
    </Container>
  );
};
