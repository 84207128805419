import { sentenceItem } from '../models/interfaces';
import { filterEnum } from '../models/interfaces';

export const getRowColor = (sentence: sentenceItem) => {
  let color = 'black';

  switch (true) {
    case sentence.type === 'Expectation':
      color = '#FFBF00';
      break;
    case sentence.type === 'Obligation':
      color = '#FF0000';
      break;
    default:
      color = '#000000';
      break;
  }
  return color;
};

const noHighlight = {
  fill: 'white',
  fillOpacity: '0.1',
  strokeWidth: '0',
  stroke: 'none'
};

const greenHighLight = {
  fill: '#F8FF00',
  fillOpacity: '0.3',
  strokeWidth: '0.1',
  stroke: 'black'
};

const highLightRed = {
  fill: 'red',
  fillOpacity: '0.2',
  strokeWidth: '1',
  stroke: 'red'
};
const highLightOrange = {
  fill: 'orange',
  fillOpacity: '0.2',
  strokeWidth: '0',
  stroke: 'none'
};

const includesFilterValue = (
  sentence: sentenceItem,
  burdenAttFilter: string[]
) => {
  // console.log('testing sentence', sentence.burden_attribution);
  // console.log('attribution filetr ', burdenAttFilter);
  if (sentence.burden_attribution.length === 0) {
    return false;
  }
  for (const value of sentence.burden_attribution[0]) {
    // console.log('sentence value', value);
    if (burdenAttFilter.includes(value)) {
      // console.log('returning true');
      return true;
    }
  }
  return false;
};

export const rectangleStyle = (
  sentence: sentenceItem,
  selectedItems: sentenceItem[],
  filterValue: filterEnum | null,
  controlFilter: string,
  burdenAttFilter: string[]
) => {
  let style: {
    fill?: string;
    strokeWidth?: string;
    stroke?: string;
    fillOpacity?: string;
    strokeOpacity?: string;
    textDecoration?: string;
  } = {};
  const isFiltering = filterValue ? true : false;
  let isFilterItem = isCurrentFiltered(
    sentence,
    filterValue,
    burdenAttFilter,
    controlFilter
  );
  // if (isFilterItem) {
  //   // console.log('text', sentence.text);
  // }
  // if (filterValue) {
  //   switch (true) {
  //     case sentence.Time_Information &&
  //       sentence.Time_Entities &&
  //       sentence.Time_Entities.length > 0 &&
  //       sentence.burden &&
  //       filterValue === filterEnum.Likely_Due_Date:
  //       isFilterItem = true;
  //       break;
  //     // case filterValue === filterEnum.people_org && (sentence.burden && )
  //     //   sentence.people_orgs.length > 0:
  //     //   isFilterItem = true;
  //     //   break;
  //     case filterValue === filterEnum.Financial_Information &&
  //       sentence.Financial_Information:
  //       isFilterItem = true;
  //       break;
  //     case filterValue === filterEnum.service &&
  //       sentence.parameter_arguments &&
  //       sentence.parameter_arguments.length > 0 &&
  //       sentence.burden:
  //       isFilterItem = true;
  //       break;
  //     case filterValue === filterEnum.burden_attribution &&
  //       includesFilterValue(sentence, burdenAttFilter):
  //       isFilterItem = true;
  //       break;
  //     case filterValue === filterEnum.location && sentence.locations.length > 0:
  //       isFilterItem = true;
  //       break;
  //     case filterValue === filterEnum.terminiation &&
  //       (sentence.termination || sentence.penalties):
  //       isFilterItem = true;
  //       break;
  //     case filterValue === filterEnum.control &&
  //       sentence.predicted_label_control === controlFilter:
  //       isFilterItem = true;
  //       break;
  //   }
  // }
  // only show the filterd items highlitghted
  if (isFiltering) {
    if (isFilterItem) {
      style = { ...greenHighLight };
    } else {
      style = { ...noHighlight };
    }
  }
  if (!isFiltering) {
    switch (true) {
      case sentence.burden:
        style = { ...highLightRed };
        break;
      case !sentence.burden:
        style = { ...noHighlight };
        break;
      default:
        style.fill = 'white';
        style.fillOpacity = '0.2';
        style.stroke = 'none';
    }
  }

  return style;
};

// export const isCurrentFiltered = (
//   sentence: sentenceItem,
//   filterValue: filterEnum | null,
//   burdenAttFilter: string[],
//   controlFilter: string
// ) => {
//   let isFilterItem = false;
//   switch (true) {
//     case (sentence.Time_Information ||
//       (sentence.Time_Entities && sentence.Time_Entities.length > 0) ||
//       (sentence.date_arguments &&
//         sentence.date_arguments?.length > 0 &&
//         sentence.burden)) &&
//       filterValue === filterEnum.Likely_Due_Date:
//       isFilterItem = true;
//       break;
//     // case filterValue === filterEnum.people_org && (sentence.burden && )
//     //   sentence.people_orgs.length > 0:
//     //   isFilterItem = true;
//     //   break;
//     case filterValue === filterEnum.Financial_Information &&
//       sentence.Financial_Information:
//       isFilterItem = true;
//       break;
//     case filterValue === filterEnum.service &&
//       sentence.parameter_arguments &&
//       sentence.parameter_arguments.length > 0 &&
//       sentence.burden:
//       isFilterItem = true;
//       break;
//     case filterValue === filterEnum.burden_attribution &&
//       includesFilterValue(sentence, burdenAttFilter):
//       isFilterItem = true;
//       break;
//     case filterValue === filterEnum.location && sentence.locations.length > 0:
//       isFilterItem = true;
//       break;
//     case filterValue === filterEnum.terminiation &&
//       (sentence.termination || sentence.penalties):
//       isFilterItem = true;
//       break;
//     case filterValue === filterEnum.control &&
//       sentence.predicted_label_control === controlFilter:
//       isFilterItem = true;
//       break;
//     case filterValue === filterEnum.starred && sentence.starred:
//       isFilterItem = true;
//       break;
//   }
//   return isFilterItem;
// };

export const isCurrentFiltered = (
  sentence: sentenceItem,
  filterValue: filterEnum | null,
  burdenAttFilter: string[],
  controlFilter: string
) => {
  let isFilterItem = false;
  switch (true) {
    // updated
    // case filterValue === filterEnum.Likely_Due_Date &&
    //   sentence.burden &&
    //   !sentence.Percent_Information &&
    //   !sentence.Financial_Information &&
    //   (sentence.Time_Information || sentence.date_arguments?.length > 0):
    //   isFilterItem = true;
    //   break;
    // case filterValue === filterEnum.people_org && (sentence.burden && )
    //   sentence.people_orgs.length > 0:
    //   isFilterItem = true;
    //   break;
    // updated
    case filterValue === filterEnum.financial_due_dates &&
      (sentence.financial_due_date || sentence.obligation_due_date):
      isFilterItem = true;
      break;
    // updated
    case filterValue === filterEnum.Financial_Information &&
      sentence.Financial_Information:
      isFilterItem = true;
      break;
    // updated
    case filterValue === filterEnum.service &&
      sentence.Percent_Information &&
      sentence.Time_Information &&
      sentence.burden:
      isFilterItem = true;
      break;
    case filterValue === filterEnum.burden_attribution &&
      includesFilterValue(sentence, burdenAttFilter):
      isFilterItem = true;
      break;
    // updated
    case filterValue === filterEnum.location && sentence.location:
      isFilterItem = true;
      break;
    // updated
    case filterValue === filterEnum.terminiation &&
      (sentence.termination || sentence.penalties):
      isFilterItem = true;
      break;
    // updated
    case filterValue === filterEnum.control &&
      sentence.predicted_label_control === controlFilter:
      isFilterItem = true;
      break;
    case filterValue === filterEnum.starred && sentence.starred:
      isFilterItem = true;
      break;
    case filterValue === filterEnum.unclassified_burden &&
      sentence.burden &&
      sentence.predicted_label_control === 'General':
      isFilterItem = true;
      break;
  }
  return isFilterItem;
};
