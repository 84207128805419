import React, { useContext } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import { UserContextProvider } from '../context/authContext';
import { ProtectedRoute } from './ProtectedRoute';
import { LoginPage } from './LoginPage';
import { App } from '../App';
import { DocumentContextProvider } from '../context/documentContext';
import { ProjectContextProvider } from '../context/projectContext';

export const Routing = () => {
  return (
    <Router>
      <UserContextProvider>
      <ProjectContextProvider>
        <DocumentContextProvider>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route element={<ProtectedRoute />}>
              <Route element={<App />} path="/app/*" />
              {/* <Route path="/" element={<LoginPage />} /> */}
              <Route
                path="*"
                element={<Navigate to="/app/landing" replace />}
              />
            </Route>
          </Routes>
        </DocumentContextProvider>
      </ProjectContextProvider>
      </UserContextProvider>
    </Router>
  );
};
