import {
  Box,
  Button,
  Cards,
  ExpandableSection,
  Header,
  Multiselect,
  Select,
  SpaceBetween
} from '@cloudscape-design/components';
import { ProjectContext } from '../../../context/projectContext';
import { useContext, useEffect, useState } from 'react';
import { OptionDefinition } from '@cloudscape-design/components/internal/components/option/interfaces';
import { DocumentContext } from '../../../context/documentContext';
import axios from 'axios';
import { UserContext } from '../../../context/authContext';
import { kbSentenceItem, sentenceItem } from '../../../models/interfaces';
import { getKBDocLinkAndOpen } from '../../../helpers/getAndOpenLink';
import ShowMoreText from 'react-show-more-text';
import '../style/showMore.css';

export const DocumentMapper = () => {
  const { mainProjects, selectedProjects } = useContext(ProjectContext);
  const { auth } = useContext(UserContext);
  const { allDocuments, selectedItems, mappedSaveLoading, saveMappedItems } =
    useContext(DocumentContext);
  const [folderToTarget, setFolderToTarget] = useState<OptionDefinition>({
    label: 'Select Folder',
    value: ''
  });
  const [folderOptions, setFolderOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [documentsToTarget, setDocumentsToTarget] = useState<
    OptionDefinition[]
  >([]);
  const [folderDocsList, setFolderDocsList] = useState<
    { label: string; value: string }[]
  >([]);
  const [documentsToSearch, setDocumentsTosearch] = useState<string[]>([]);
  const [mappingLoading, setMappingLoading] = useState<boolean>(false);
  const [mappedResults, setMappedResults] = useState<kbSentenceItem[]>([]);
  const [selectedResults, setSelectedResults] = useState<kbSentenceItem[]>([]);

  useEffect(() => {
    console.log('selected prok=ject changed in mapper', selectedProjects);
    if (!(selectedProjects.length > 0)) {
      return;
    } else {
      const optionS = selectedProjects[0]?.folders.map((ele) => {
        return { label: ele.folderName, value: ele._id };
      });
      console.log('settting new folders', optionS);
      setFolderOptions(optionS);
    }
  }, [selectedProjects]);

  useEffect(() => {
    if (documentsToTarget.length > 0 || folderDocsList.length > 0) {
      setDocumentsToTarget([]);
      setFolderDocsList([]);
    }
    console.log('number of docs', allDocuments);
    const tempDocs = allDocuments.filter(
      (ele) => ele.folder === folderToTarget.value
    );
    const itemsToSet = tempDocs.map((ele) => {
      return { label: ele.documentFileName, value: ele._id };
    });
    setFolderDocsList(itemsToSet);
  }, [allDocuments, folderToTarget]);

  useEffect(() => {
    const tempDocIds = [];
    if (!(documentsToTarget.length > 0) && folderToTarget) {
      const filteredDocs = allDocuments.filter(
        (ele) => ele.folder === folderToTarget.value
      );
      setDocumentsTosearch(filteredDocs.map((ele) => ele._id));
    }
    if (documentsToTarget.length > 0 && folderToTarget) {
      const filteredDocs = documentsToTarget.map((ele) => ele.value!);
      setDocumentsTosearch(filteredDocs);
    }
  }, [folderToTarget, documentsToTarget]);

  const mapToDocuments = async () => {
    try {
      setMappedResults([]);
      setMappingLoading(true);
      const results = await axios.post(
        `${process.env.REACT_APP_KORU_API}/sentenceVector?type=specificScope`,
        { sentence: selectedItems[0], documentIdsToSearch: documentsToSearch },
        auth
      );
      // remove the current selected values and add to front of array before settings state.
      if (selectedItems[0]?.mappedTargetSentences.length > 0) {
        const currentIds = selectedItems[0].mappedTargetSentences.map(
          (ele) => ele._id
        );
        const excludingCurrent = results.data.results.filter(
          (ele: kbSentenceItem) => !currentIds.includes(ele._id)
        );
        const tempArray = [
          ...selectedItems[0].mappedTargetSentences,
          ...excludingCurrent
        ];
        setMappedResults(tempArray);
      } else {
        setMappedResults(results.data.results);
      }
      setMappingLoading(false);
      console.log('result', results.data);
    } catch (e) {
      const error = e as Error;
      setMappingLoading(false);
      console.log('Error', error.message);
    }
  };

  const saveMapped = async () => {
    saveMappedItems(selectedResults);
    setSelectedResults([]);
  };

  useEffect(() => {
    setMappedResults([]);
    console.log('selectedItems.length', selectedItems.length);
    console.log('mapped items', selectedItems[0]?.mappedTargetSentences);
    if (
      selectedItems &&
      selectedItems.length > 0 &&
      selectedItems[0]?.mappedTargetSentences
    ) {
      if (selectedItems[0]?.mappedTargetSentences.length > 0) {
        setMappedResults([...selectedItems[0].mappedTargetSentences]);
        setSelectedResults(selectedItems[0].mappedTargetSentences);
      }
    }
  }, [selectedItems]);

  const isSaveButtonDisabled = () => {
    if (
      selectedItems[0]?.mappedTargetSentences.length > 0 &&
      selectedItems[0]?.mappedTargetSentences.length > selectedResults.length
    ) {
      return false;
    }
    if (
      selectedItems[0]?.mappedTargetSentences.length === 0 &&
      selectedResults.length > 0
    ) {
      return false;
    }
    if (
      JSON.stringify(selectedItems[0]?.mappedTargetSentences) !==
      JSON.stringify(selectedResults)
    ) {
      return false;
    }

    return true;
  };

  return (
    <ExpandableSection
      variant="container"
      headerText="Traced TO Documents"
      headerActions={
        <SpaceBetween size="s" direction="horizontal">
          <Button
            disabled={isSaveButtonDisabled()}
            onClick={() => saveMapped()}
            loading={mappedSaveLoading}
          >
            Save Mapped
          </Button>
          <Button
            disabled={documentsToSearch.length > 0 ? false : true}
            onClick={() => mapToDocuments()}
            loading={mappingLoading}
          >
            Map
          </Button>
        </SpaceBetween>
      }
    >
      {/* <div
        style={{
          minHeight: '20vh',
          width: '100%',
          overflowY: 'scroll',
          scrollbarWidth: 'none'
        }}
      > */}
      <SpaceBetween size={'s'}>
        {selectedItems.length > 0 && <Box>{selectedItems[0].text}</Box>}
        <SpaceBetween size="xs" direction="horizontal">
          <Select
            selectedOption={folderToTarget}
            placeholder="Scope"
            options={folderOptions}
            onChange={({ detail }) => setFolderToTarget(detail.selectedOption)}
          />

          <Multiselect
            placeholder="Please select filter criteria"
            selectedOptions={documentsToTarget}
            options={folderDocsList}
            onChange={({ detail }) => {
              console.log('dtail', detail);
              setDocumentsToTarget([...detail.selectedOptions]);
            }}
          />
        </SpaceBetween>
        {/* <SpaceBetween size={'s'}> */}
        <div style={{ height: '60vh', overflow: 'scroll' }}>
          <Cards
            onSelectionChange={({ detail }) => {
              console.log('detail from change', detail);
              setSelectedResults(detail?.selectedItems ?? []);
            }}
            selectedItems={selectedResults}
            cardDefinition={{
              header: (item) => (
                <p style={{ fontStyle: 'italic', fontWeight: 'normal' }}>
                  {item.text}
                </p>
              ),
              sections: [
                {
                  id: 'link',
                  // header: 'Link',
                  content: (item) => (
                    // <Link href="https://www.example.com" external>
                    //   {item.Document.documentFileName }
                    // </Link>
                    <Button
                      onClick={() =>
                        getKBDocLinkAndOpen(
                          item.Document._id,
                          item.PageNumber,
                          auth
                        )
                      }
                      href=""
                      variant="link"
                      // loading={preLoading}
                    >
                      {item.Document.documentFileName}
                    </Button>
                  )
                },
                {
                  id: 'Context',
                  header: 'Context',
                  content: (item) => (
                    <>
                      <ShowMoreText lines={3} anchorClass="showMoreClass">
                        {item?.Context?.text ? item.Context.text : ''}
                      </ShowMoreText>
                    </>
                  )
                },

                {
                  id: 'score',
                  header: 'Similarity',
                  content: (item) =>
                    !isNaN(item.score * 100)
                      ? `${(item.score * 100).toFixed(2)} %`
                      : ''
                }
              ]
            }}
            cardsPerRow={[{ cards: 1 }]}
            items={mappedResults}
            // items={selectedItemsKbResults}
            loadingText="Loading resources"
            selectionType="multi"
            trackBy="_id"
            visibleSections={['text', 'link', 'Context', 'score']}
            empty={
              <Box
                margin={{ vertical: 'xs' }}
                textAlign="center"
                color="inherit"
              >
                <SpaceBetween size="m">
                  <b>No resources found</b>
                </SpaceBetween>
              </Box>
            }
          />
        </div>
        {/* </SpaceBetween> */}
      </SpaceBetween>
      {/* </div> */}
    </ExpandableSection>
  );
};
