import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState
} from 'react';
import { FolderItem, IProjectContext, Projects } from '../models/interfaces';
import axios from 'axios';
import { UserContext } from './authContext';
import { useSearchParams } from 'react-router-dom';
interface Props {
  children?: ReactNode;
}
export const ProjectContext = createContext({} as IProjectContext);

export const ProjectContextProvider: React.FC<Props> = (props) => {
  const { auth, token } = useContext(UserContext);
  const [mainProjects, setMainProjects] = useState<Projects[]>([]);
  const [selectedProjects, setSelectedProjects] = useState<Projects[]>([]);
  const [folderName, setFolderName] = useState<string>('');
  const [createFolderLoading, setCreateFolderLoading] =
    useState<boolean>(false);
  const [newFolderModalVisible, setNewFolderModalVisible] =
    useState<boolean>(false);
  const [selectedFolder, setSelectedFolder] = useState<FolderItem>(
    {} as FolderItem
  );
  const [queryParameters] = useSearchParams();
  const getAllProjects = async (currentProject?: string) => {
    if (!token) return;
    try {
      const results = await axios.post(
        `${process.env.REACT_APP_KORU_API}/folder?type=getProjects`,
        {},
        auth
      );
      setMainProjects(results.data.result);
      const pId = queryParameters.get('projectId');
      if (pId || selectedProjects.length > 0) {
        const localPid = pId ? pId : selectedProjects[0]._id;
        console.log('setting current project from string');
        const projectToFind = results.data.result.filter(
          (ele: Projects) => ele._id === localPid
        );
        console.log('project to find', projectToFind);
        if (projectToFind.length > 0) {
          setSelectedProjects(projectToFind);
        }
      }
    } catch (e) {
      const error = e as Error;
      console.log('Error', error.message);
    }
  };
  useEffect(() => {
    if (!token) return;
    const projectId = queryParameters.get('projectId');
    if (projectId) {
      getAllProjects(projectId);
    } else {
      getAllProjects();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getAllProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const createNewFolder = async () => {
    setCreateFolderLoading(true);
    console.log('Project id', selectedProjects[0]._id);
    console.log('new folder name', folderName);
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_KORU_API}/folder?type=newFolder`,
        {
          newFolder: {
            folderName: folderName,
            project: selectedProjects[0]._id
          }
        },
        auth
      );
      console.log('new project::', result.data.result);
      getAllProjects();
      setCreateFolderLoading(false);
      setNewFolderModalVisible(false);
      setFolderName('');
    } catch (e) {
      const error = e as Error;
      console.log('error', error.message);
    }
  };

  const updateFolder = async (id: string) => {
    console.log('Project id', selectedProjects[0]._id);
    console.log('new folder name', folderName);
    setCreateFolderLoading(true);
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_KORU_API}/folder?type=update`,
        {
          updates: {
            folderName: folderName
          },
          _id: id
        },
        auth
      );
      console.log('update project::', result.data.result);
      getAllProjects();
      setCreateFolderLoading(false);
      setNewFolderModalVisible(false);
      setFolderName('');
    } catch (e) {
      const error = e as Error;
      console.log('error', error.message);
    }
  };

  const deleteFolder = async (id: string) => {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_KORU_API}/folder?type=deleteFolder`,
        {
          _id: id
        },
        auth
      );
      getAllProjects();
    } catch (e) {
      const error = e as Error;
      console.log('error', error.message);
    }
  };

  const value = {
    mainProjects,
    getAllProjects,
    selectedProjects,
    setSelectedProjects,
    folderName,
    setFolderName,
    createNewFolder,
    createFolderLoading,
    updateFolder,
    newFolderModalVisible,
    setNewFolderModalVisible,
    deleteFolder,
    selectedFolder,
    setSelectedFolder
  };

  return (
    <ProjectContext.Provider value={value}>
      {props.children}
    </ProjectContext.Provider>
  );
};
