import { SpaceBetween } from '@cloudscape-design/components';
import { ProjectTableView } from './projectTableView';
import { DocumentTableView } from './DocumentTableView';
import { useContext, useState } from 'react';
import { FolderItem, Projects } from '../models/interfaces';
import { FolderTableView } from './FolderTableView';
import { ProjectContext } from '../context/projectContext';
import { LoadingBar } from '@cloudscape-design/chat-components';

export const DocumentViewerContainer = () => {
  const {
    selectedProjects,
    setSelectedProjects,
    selectedFolder,
    setSelectedFolder
  } = useContext(ProjectContext);
  // const [selectedProject, setSelectedProject] = useState<Projects[]>([]);
  // const [projects, setProjects] = useState<Projects[]>([]);
  const [viewFolder, setViewFolder] = useState<boolean>(false);

  return (
    <div
      style={{
        overflowY: 'scroll',
        scrollbarWidth: 'none'
      }}
    >
      <SpaceBetween size={'xs'} direction="vertical">
        {!viewFolder && (
          <ProjectTableView
            // setSelectedProject={setSelectedProjects}
            // setProjects={setProjects}
            // projects={projects}
            // selectedProjects={selectedProject}
            setViewFolder={setViewFolder}
          />
        )}
        {selectedProjects.length > 0 && viewFolder && (
          <SpaceBetween size={'xs'} direction="vertical">
            <FolderTableView
              folders={selectedProjects[0].folders}
              setViewFolders={setViewFolder}
            />
            <DocumentTableView />
          </SpaceBetween>
        )}
      </SpaceBetween>
    </div>
  );
};
